import { AvaNetwork } from '@/js/AvaNetwork'

export const BetanetConfig = new AvaNetwork(
    'Betanet',
    'https://rpc-betanet.lamina1.com:443',
    201,
    '',
    '',
    false
);

/*
export const TestnetConfig = new AvaNetwork(
    'Testnet',
    'https://rpc-testnet.lamina1.com:443',
    7649,
    '',
    '',
    false
)
*/

export const LocalConfig = new AvaNetwork('Local', 'http://localhost:9650', 12345, '', '', false)
